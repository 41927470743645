<template>
  <!-- Content Add Modal -->
  <b-modal
    id="add-sppd-modal"
    centered
    title="Unggah SPPD"
    size="md"
    hide-footer
  >
    <div>
      <b-form-group
        label="File SPPD"
        :invalid-feedback="classroomContent.errors.sppd.message"
      >
        <b-form-file
          v-model="classroomContent.data.sppd"
          required
          :state="classroomContent.errors.sppd.state"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          accept=""
        />
      </b-form-group>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="w-100"
        variant="primary"
        :disabled="classroomContent.data.sppd ? false : true"
        @click="handleSubmit"
      >
        <i
          v-show="!isLoading"
          class="font-medium-2 ti ti-circle-plus"
        />
        <b-spinner
          v-show="isLoading"
          small
          variant="light"
        />
        Unggah
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BFormTextarea,
  BSpinner,
  BFormFile,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { reqUploadSppdLetterOfAssignment } from '@/api/admin/letterOfAssignment'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BSpinner,
    BRow,
    BCol,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      classroomContent: {
        data: {
          sppd: null,
        },
        errors: {
          sppd: { state: null, message: '' },
        },
      },
    }
  },
  mounted() {
  },
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    resetForm() {
      this.classroomContent.data.sppd = null
    },
    resetError() {
      this.classroomContent.errors.sppd.state = null
    },
    formValidation() {
      this.isLoading = true
      const isValid = true
      return isValid
    },
    async handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.formValidation()) {
        // wrap into form data
        const data = new FormData()

        for (const index in this.classroomContent.data) {
          data.append(index, this.classroomContent.data[index])
        }
        this.resetError()

        // create classroom content
        await reqUploadSppdLetterOfAssignment(this.$route.params.id, data)
          .then(response => {
            // set loading
            this.isLoading = false

            // reset modal content value
            this.resetForm()

            this.showToast(
              'success',
              'Check',
              'Berhasil',
              'File SPPD Berhasil diunggah',
            )
            this.$bvModal.hide('add-sppd-modal')
            this.$emit('reload')
          })
          .catch(error => {
            this.isLoading = false
            const { errors } = error.response.data
            if (errors['participant']) {
              this.showToast(
                'danger',
                'XIcon',
                'Gagal',
                error.response.data.message || 'Gagal',
              )
            } else {
              for (const index in errors) {
                const element = errors[index]
                this.classroomContent.errors[index].state = false
                this.classroomContent.errors[index].message = element[0]
              }
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
#content-add-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}
</style>
